
var Helpers = (function($){
    
    var Helper = {
        msgTimeout: 7000,
        currentPopover: null,
        loginUrl: "",
        
        languageData: {},
        
        isMomentDate: function(val) {
            return moment(val, 'DD.MM.YYYY', true).isValid();
        },
        initTypehead: function() {
            $(".typeahead").typeahead();
        },
        
        hideAllPopovers: function() {
            $("[data-toggle=popover]").popover("hide");
        },
        
        initDateTimeRangePickersWithOutTime: function() {
            
            $('.datetimepicker-from').datetimepicker({
                locale: moment.locale('lv'),
                format: 'DD.MM.YYYY'
            });
            $('.datetimepicker-till').datetimepicker({
                locale: moment.locale('lv'),
                format: 'DD.MM.YYYY'
            });
        },
        
        initDateTimePiker: function() {
            
            $('.datetimepicker').datetimepicker({
                locale: moment.locale('lv'),
                format: 'DD.MM.YYYY',
            });
        },
        
        initDateTimeRangePickers: function(minDate, maxDate) {
            
            $('.datetimepicker-from').datetimepicker({
                sideBySide: true,
                minDate: minDate,
                maxDate: maxDate,
                keepInvalid: true,
                viewDate: minDate,
                locale: moment.locale('lv')
            });
            $('.datetimepicker-till').datetimepicker({
                sideBySide: true,
                minDate: minDate,
                viewDate: minDate,
                maxDate: maxDate,
                keepInvalid: true,
                locale: moment.locale('lv')
            });
            $(".datetimepicker-from").on("dp.change", function (e) {
                $('.datetimepicker-till').data("DateTimePicker").minDate(e.date);
            });
            $(".datetimepicker-till").on("dp.change", function (e) {

                $('.datetimepicker-from').data("DateTimePicker").maxDate(e.date);
            });
        },
        initPopovers: function(element){
            
            var self = this;
            $(element).on("click", "[data-toggle=popover]",function(){
                if(self.currentPopover) {
                    self.currentPopover.popover("hide");
                }
                $(this).popover({html: "true",trigger: "manual"})
                        .on('shown.bs.popover', function() {
                            // Define elements
                            var current_trigger=$(this);
                            self.currentPopover=current_trigger.data('bs.popover').tip();

                            // Activate close button
                            self.currentPopover.find('.popover-delete-no').click(function() {
                                self.currentPopover.popover("hide");
                            });
                        });
                
                $(this).popover("toggle");
            });
            
            $('html').on('mouseup', function(e) {
                if(!$(e.target).closest('[data-toggle=popover]').length) {
                    $('[data-toggle=popover]').each(function(){
                        $(this).popover('hide');
                    });
                }
            });
        },
        
        initPopovers2: function(element){
            
            var self = this;
            $(element).on("click",function(){
                if(self.currentPopover) {
                    self.currentPopover.popover("hide");
                }
                $(this).popover({html: "true",trigger: "manual"})
                        .on('shown.bs.popover', function() {
                            // Define elements
                            var current_trigger=$(this);
                            self.currentPopover=current_trigger.data('bs.popover').tip();

                            // Activate close button
                            self.currentPopover.find('.popover-delete-no').click(function() {
                                self.currentPopover.popover("hide");
                            });
                        });
                
                $(this).popover("toggle");
            });
            
            $('html').on('mouseup', function(e) {
                if(!$(e.target).closest('[data-toggle=popover]').length) {
                    $('[data-toggle=popover]').each(function(){
                        $(this).popover('hide');
                    });
                }
            });
        },
        
        initChosens: function(container, options) {
            
            if(!options) {
                options = {width: "100%"};
            }
            
            if(container) {
                $(container).find('.chosen-select').chosen(options);
            }
            else {
                $('.chosen-select').chosen(options);
            }
        },
        
        initNumeric: function() {
            $(document.body).on("click",".numeric",function(){
                $(this).numeric({ decimal : ".",  negative : false, scale: 2 });
            });
        },
        initRangeDatepickers: function() {
            $('.input-daterange').datepicker({
                keyboardNavigation: false,
                forceParse: false,
                autoclose: true,
                format: "dd.mm.yyyy"
            });

        },
        
        initMonthDatetimePickers: function() {
            return $(".datepicker").datepicker( {
                format: "mm.yyyy",
                startView: "months", 
                minViewMode: "months",
                autoclose: true,
            });
        },
        initMonthDatetimePickers2: function() {
            return $(".datepicker").datetimepicker( {
                format: "MM.YYYY", 
                viewMode: "months",
                locale: moment.locale('lv'),
                ignoreReadonly: true
            });
        },
        makeAjaxStandart: function(url,method,data,doneFunction, errorFunction, procfessdata, contentType, async) {
           
            if(!procfessdata) {
                procfessdata = true;
            }
            if(!contentType) {
                contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
            }
            
            if(async === undefined) {
                async = true;
            }
           
            var that = this;
           $("body").addClass("sk-loading");
            req = $.ajax({
                url: url,
                type: method,
                dataType: "json",
                data: data,
                processData: procfessdata,  // tell jQuery not to process the data
                contentType: contentType,
                async: async
            });
            
            req.done(doneFunction);
            req.fail(function(a,b,c) {
                
                if($.isFunction(errorFunction)) {
                    errorFunction();
                }
                
                if(a.responseText.indexOf('login_form') > -1) {
                    window.location.href = that.loginUrl;
                } 
                else
                {
                    Helper.errorMSG("", "Kļūda iegūstot datus no servera!");
                }
            });
            req.always(function(){
                $("body").removeClass("sk-loading");
            });
        },
        successMSG: function(title,msg){
            toastr.options.progressBar = true;
            toastr.success(msg, title, {timeOut: this.msgTimeout})
        },
        errorMSG: function(title,msg){
            toastr.options.progressBar = true;
            toastr.error(msg, title, {timeOut: this.msgTimeout})
        },
        warningMSG: function(title,msg){
            toastr.options.progressBar = true;
            toastr.warning(msg, title, {timeOut: this.msgTimeout})
        },
        getCorrectMSG: function(statuss,title,msg){
            if(statuss=="error")
            {
                this.errorMSG(title,msg);
            }
            if(statuss=="success")
            {
                this.successMSG(title,msg);
            }
            if(statuss=="warning")
            {
                this.warningMSG(title,msg);
            }
        },
        initIChecks: function(){
            $('.i-checks').iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
            });

        },
        initIChecksFrom: function(from){
            $(from).find(".i-checks").iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green',
            });

        },
        showSweetConfirm: function(title,text,yesTxt,noTxt,type,callBack){
            
            swal({
                imageSize: "40x40",
                title: title,   
                text: text,   
                type: type,   
                showCancelButton: true,   
                confirmButtonText: yesTxt,  
                cancelButtonText: noTxt,
                html: true
            },function(){
                if(callBack)
                {
                    callBack();
                }
            });

        },
        
        makeModal: function(modalObj, html) {
            if(modalObj != null) {
                modalObj.remove();
            }
            
            modalObj = $(html).modal();
            //$(modalObj).draggable();
            
            return modalObj;
        },
        Create2DArray: function(rows) {
          var arr = {};

          for (var i=0;i<rows;i++) {
             arr[i] = {};
          }

          return arr;
        },
        
        setZIndex: function (selector) {
            var zindex = Math.max.apply(null, $("div").map(function(){
                var z;
                return isNaN(z = parseInt($(this).css("z-index"), 10)) ? 0 : z + 10;
            }));
            
            $(selector).css("z-index", zindex);
        },
        pad: function(str, max) {
            str = str.toString();
            return str.length < max ? Helper.pad("0" + str, max) : str;
        }     
    };
    
    return Helper;
    
})(jQuery);



