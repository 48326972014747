var HTMLTemplates = (function($){
    
    var HTML = {
        
        getOrdersButtonsHTML: function(id,href, provide, payed, showPay){
            return '<div class="btn-group pull-right">'+
                        (showPay ? '<button title="Apmaksāt rēķinu" data-id='+id+' class="btn btn-warning btn-xs pay-action" type="button"><i class="fa fa-credit-card"></i></button>' : "" )+
                        '<a title="Rēķins PDF formātā" target="_blank" href="'+href+'" data-style="zoom-in" data-id='+id+' class="btn btn-danger btn-xs pdf-action"><i class="fa fa-file-pdf-o"></i></a>'+
                        '<button title="Apskatīt rēķinu" data-style="zoom-in" data-id='+id+' class="btn btn-success btn-xs view-action-order ladda-button" type="button"><i class="fa fa-eye"></i></button>'+
                        (provide ? '<button data-style="zoom-in" data-id='+id+' class="btn btn-primary btn-xs provide-action ladda-button" type="button">Sniegt</button>' : '')+
                        (payed ? '<button data-style="zoom-in" data-id='+id+' class="btn btn-warning btn-xs payed-action ladda-button" type="button">Apmaksāts</button>' : '')+
                    '</div>';
        },
        getActionButtonsHTML: function(id, showView, showEdit, showDelete){
             return '<div class="btn-group pull-right">'+
                    (showView ? '<button data-style="zoom-in" data-id='+id+' class="btn btn-success btn-xs view-action ladda-button" type="button">'+Helpers.languageData["view"]+'</button>' : '')+
                    (showEdit ? '<button data-style="zoom-in" data-id='+id+' class="btn btn-primary btn-xs edit-action ladda-button" type="button">'+Helpers.languageData["edit"]+'</button>' : '')+
                    (showDelete ? '<button data-html="true" data-title="'+Helpers.languageData["popover_title"]+'" data-container="body" data-toggle="popover" data-placement="top" data-content="'+HTMLTemplates.getPopoverDeleteHtmlContent(id)+'" data-id='+id+' class="btn btn-danger btn-xs delete-action" type="button">'+Helpers.languageData["delete"]+'</button>' : '')+
                '</div>';
        },
        getPopoverDeleteHtmlContent: function(deleteItemId){
            return "<div class='btn-group center-div'>\n\
                        <button data-style='zoom-in' data-id='"+deleteItemId+"' class='btn btn-success popover-delete-yes ladda-button'>Jā</button>\n\
                        <button class='btn btn-danger popover-delete-no'>Nē</button>\n\
                    </div>";
        },
        getProductCategoriesActionButtonsHTML: function(id, showEdit, showDelete){
             return '<div class="btn-group pull-right">'+
                    (showEdit ? '<button data-style="zoom-in" data-id='+id+' class="btn btn-primary btn-xs edit-action ladda-button" type="button">'+Helpers.languageData["edit"]+'</button>' : '')+
                    (showDelete ? '<button data-html="true" data-title="Vai tiešām vēlaties dzēst šo ierakstu" data-container="body" data-toggle="popover" data-placement="top" data-content="'+HTMLTemplates.getPopoverDeleteHtmlContent(id)+'" data-id='+id+' class="btn btn-danger btn-xs delete-action" type="button">'+Helpers.languageData["delete"]+'</button>' : '')+
                '</div>';
        },
        
        getProductsActionsButtonsHTML: function(id, showEdit, showDelete, showCoppy){
             return '<div class="btn-group pull-right">'+
                    (showCoppy ? '<button title="Dublēt" data-id='+id+' class="btn btn-warning btn-xs duplicate-product-action" type="button"><i class="fa fa-copy"></i></button>' : "") +
                    (showEdit ? '<button title="Labot" data-id='+id+' class="btn btn-primary btn-xs edit-action" type="button"><i class="fa fa-edit"></i></button>' : '')+
                    (showDelete ? '<button title="Dzēst" data-html="true" data-title="Vai tiešām vēlaties dzēst šo ierakstu" data-container="body" data-toggle="popover" data-placement="top" data-content="'+HTMLTemplates.getPopoverDeleteHtmlContent(id)+'" data-id='+id+' class="btn btn-danger btn-xs delete-action" type="button"><i class="fa fa-remove"></i></button>' : '')+
                '</div>';
        },
        getUsersActionButtonsHTML: function(id, showView, showDelete){
             return '<div class="btn-group pull-right">'+
                    (showView ? '<button data-style="zoom-in" data-id='+id+' class="btn btn-success btn-xs view-action ladda-button" type="button"><i class="fa fa-eye"></i></button>' : '')+
                    (showDelete ? '<button data-html="true" data-title="Vai tiešām vēlaties dzēst šo ierakstu" data-container="body" data-toggle="popover" data-placement="top" data-content="'+HTMLTemplates.getPopoverDeleteHtmlContent(id)+'" data-id='+id+' class="btn btn-danger btn-xs delete-action" type="button"><i class="fa fa-remove"></i></button>' : '')+
                '</div>';
        },
    };
    
    return HTML;
    
})(jQuery);