

var Socials = (function($){
    
    var twitterVia = "LLKC_lv";
    
    function init()
    {
        initDraugiem("draugiemLike","");
        initFacebook();
        initTwitter();
    }
    function initDraugiem(id,layout)
    {
        var p = {layout:    layout,
                 link:      window.location.href,
                 popup:     true,
                 mobile:    true
                };
       new DApi.Like(p).append(id);
    }
    function initFacebook()
    {
        $(".fb-share-button").attr("data-href",window.location.href);   
        
        var js, fjs = document.getElementsByTagName("script")[0];
        if (document.getElementById('facebook-jssdk')) return;
  
        js = document.createElement("script"); 
        js.id = 'facebook-jssdk';
        js.src = "//connect.facebook.net/lv_LV/sdk.js#xfbml=1&version=v2.5";
        fjs.parentNode.insertBefore(js, fjs);
        
        
    }
    function initTwitter()
    {
        var $twitterButton = $(".twitter-share-button");
        $twitterButton.attr("data-via",twitterVia);
        $twitterButton.attr("data-url",window.location.href);
        
        var js,
            fjs=document.getElementsByTagName("script")[0],
            p=/^http:/.test(document.location)?'http':'https';
    
        if(!document.getElementById("twitter-wjs")){
            js=document.createElement("script");
            js.id="twitter-wjs";
            js.src=p+'://platform.twitter.com/widgets.js';
            fjs.parentNode.insertBefore(js,fjs);
        }
    }
    
    var Social = {
        
        init: function(){
            init();
        }
    };
    
    return Social;
})(jQuery);


