

var Cart = (function($){
    
    function init()
    {
        
        $(document.body).on("click",".addToCart",function(){
            addToCart($(this).attr("data-id"),false);
        });
        $(document.body).on("click",".cart-product-title",function(){
            openCartProduct($(this).attr("data-url"));
        });
        $(document.body).on("click",".remove-product-all",function(){
            removeFromCart(this,true, null);
        });
        $(document.body).on("click",".remove-from-big-cart",function(){
            removeFromCart(this,true, initTouchspins);
        });
        
        $(document.body).on("click",".cart-product-container",function(e){
            e.stopPropagation();
        });
    }
    
    function initTouchspins()
    {
        $(".touchSpin").TouchSpin({
            step: 1,
            decimals: 0,
            boostat: 5,
            buttondown_class: 'btn btn-primary',
            buttonup_class: 'btn btn-primary',
            min:1
        });

    }
    
    function listenTouchspiEvents()
    {
        $(document.body).on("touchspin.on.startupspin",".touchSpin",function(e) { 
            addToCart($(this).closest(".order-product").attr("data-id"),true, initTouchspins);
        });
        
        $(document.body).on("touchspin.on.startdownspin",".touchSpin",function(e) {
            removeFromCart($(this).closest(".order-product"),false, initTouchspins);
        });
        
        
    }
    
    function pay()
    {
        window.location = C.payUrl;
    }
    
    function openCartProduct(url)
    {
        window.location = url;
    }
    function removeFromCart(element,all, doneCallback)
    {
        var token = $("#_token").val();
        var id = $(element).attr("data-id");
        
        Helpers.makeAjaxStandart(
            C.removeFromCartUrl, 
            "post", 
            {
                productid: id,
                all: all,
                _token: token
            },
            function(data) {
                if(data.statuss=="success") {
                    
                    changeCartData(data.obj);
                    if(doneCallback) {
                        doneCallback();
                    }
                }
            }
        );
    }
    
    function changeCartData(obj)
    {
        $("li.cart").html(obj.cartHtml);
        $(".bigCart").html(obj.bigCartHtml);
    }
    
    
    function addToCart(id,addToBigCart, doneCallback)
    {
        var token = $("#_token").val();
        
        Helpers.makeAjaxStandart(
            C.addToCartUrl, 
            "post", 
            {
                productid: id,
                isbigcart: addToBigCart,
                _token: token
            },
            function(data) {
                if(data.statuss=="success") {
                    
                    changeCartData(data.obj);
                    if(!addToBigCart)
                    {
                        Helpers.showSweetConfirm(
                            Helpers.languageData["product_added_successfull"],
                            "",
                            Helpers.languageData["product_added_confirm_yes"],
                            Helpers.languageData["product_added_confirm_no"],
                            data.statuss,
                            pay
                        );
                    }
                    
                    if(doneCallback) {
                        doneCallback();
                    }
                }
                else {
                    Helpers.getCorrectMSG(data.statuss, Helpers.languageData["product_added_title"],data.msg);
                }
            }
        );
    }
    
    var C = {
        
        init: init,
        addToCart: addToCart,
        removeFromCart: removeFromCart,
        initTouchspins: initTouchspins,
        listenTouchspins: listenTouchspiEvents,
        
        addToCartUrl: "",
        payUrl: "",
        removeFromCartUrl: "",
    };
    
    return C;
    
})(jQuery);